<template>
  <CRow>
    <CModal
        size="lg"
        :show.sync="largeModal"
        :no-close-on-backdrop="true"
        color="primary"
        :hide-footer="true"
    >

      <img alt="Registration Document" class="imageFormat" :src="viewImage"/>


      <template #footer style="display: none">
        <CButton @click="darkModal = false" color="danger" style="display: none"
        >Discard
        </CButton
        >
        <CButton
            @click="darkModal = false"
            color="success"
            style="display: none"
        >Accept
        </CButton
        >
      </template>
    </CModal>

    <CCol col="12" lg="12">
      <CAlert :color="color" closeButton :show.sync="dismissCountDown" fade>
        {{ err_msg }}
      </CAlert>


      <span v-if="existResponse">
        <CForm novalidate @submit.prevent="onSubmit" class="detail-div">


          <input
              type="hidden"
              model="profile_verification.type"
              :value="profile_verification.type"
          />
           <CRow>
             <CCol class="text-right">
               <div>
                 {{ $lang.profile_verification.form.scheduleVideoMeeting }} :
                 <span v-if="profile_verification.scheduleVideoMeeting === 1">
                   <b>Yes</b>
                 </span>
                 <span v-if="profile_verification.scheduleVideoMeeting === 0">
                   <b>No</b>
                 </span>
                &nbsp;&nbsp;
                 <CButton
                     :color="color"
                     shape="pill"
                     :disabled="meetingIsDisabled"
                     v-on:click="sendCalenderLink(profile_verification.companyId)"
                 >
                    {{ $lang.profile_verification.form.calenderLink }}
                  </CButton>

               </div>
             </CCol>
           </CRow>
            <div v-if="profile_verification.type === 'College'">
              <CRow>
                <CCol sm="6" md="4" lg="4">
                  <p class="custome-labal">
                    {{ $lang.profile_verification.form.user }}
                  </p>
                  <p class="custome-detail">
                    {{ profile_verification.company }}
                  </p>
                </CCol>

                <CCol sm="6" md="4" lg="4">
                  <p class="custome-labal">
                    {{ $lang.profile_verification.form.name }}
                  </p>
                  <p class="custome-detail">{{ profile_verification.name }}</p>
                </CCol>

                <CCol sm="6" md="4" lg="4">
                  <p class="custome-labal">
                    {{ $lang.profile_verification.form.address }}
                  </p>
                  <p class="custome-detail">
                    {{ profile_verification.address }}
                  </p>
                </CCol>

                <CCol sm="6" md="4" lg="4">
                  <p class="custome-labal">
                    {{ $lang.profile_verification.form.registeredNumber }}
                  </p>
                  <p class="custome-detail">
                    {{ profile_verification.registeredNumber }}
                  </p>
                </CCol>

                <CCol sm="6" md="4" lg="4">
                  <p class="custome-labal">
                    {{ $lang.profile_verification.form.regDate }}
                  </p>
                  <p class="custome-detail">
                    {{ getDateWithFormat(profile_verification.regDate) }}
                  </p>
                </CCol>
              </CRow>

              <CRow>
                <CCol
                    sm="6"
                    md="4"
                    lg="4"
                    v-show="profile_verification.companyIdentificationNumber"
                >
                  <p class="custome-labal">
                    {{
                      $lang.profile_verification.form
                          .companyIdentificationNumber
                    }}
                  </p>
                  <p class="custome-detail">
                    {{ profile_verification.companyIdentificationNumber }}
                    ({{ profile_verification.companyIdentificationName }})

                    <br/>
                    <span
                        :style="cinApiMessageColor"
                        v-show="profile_verification.cinApiMessage"
                    >
                      <strong>{{ profile_verification.cinApiMessage }}</strong>
                    </span>
                  </p>

                  <CButton
                      v-if="profile_verification.cinIsVerified !== 1"
                      color="success"
                      shape="pill"
                      v-on:click="
                      verifyIdentificationNumber(
                        userId,
                        profile_verification.companyIdentificationNumber,
                        profile_verification.companyIdentificationName,
                        1
                      )
                    "
                  >
                    {{ $lang.profile_verification.form.clickToverify }}
                  </CButton>
                </CCol>

                <CCol
                    sm="6"
                    md="4"
                    lg="4"
                    v-show="profile_verification.userIdentificationNumber"
                >
                  <p class="custome-labal">
                    {{
                      $lang.profile_verification.form.userIdentificationNumber
                    }}
                  </p>
                  <p class="custome-detail">
                    {{ profile_verification.userIdentificationNumber }}
                    ({{ profile_verification.userIdentificationName }})
                    <br/>
                    <span
                        :style="uinApiMessageColor"
                        v-show="profile_verification.uinApiMessage"
                    >
                      <strong>{{ profile_verification.uinApiMessage }}</strong>
                    </span>
                  </p>

                  <CButton
                      v-if="profile_verification.uinIsVerified !== 1"
                      color="success"
                      shape="pill"
                      v-on:click="
                      verifyIdentificationNumber(
                        userId,
                        profile_verification.userIdentificationNumber,
                        profile_verification.userIdentificationName,
                        0
                      )
                    "
                  >
                    {{ $lang.profile_verification.form.clickToverify }}
                  </CButton>
                </CCol>
              </CRow>

              <CRow>
                <CCol sm="6" md="4" lg="12">
                  <div class="form-group">
                    <label class="form__label"
                    >{{ $lang.profile_verification.form.regDoc }}
                      <required_span/>
                    </label>

                    <CButton
                        v-if="
                        ext !== 'jpg' &&
                        ext !== 'jpeg' &&
                        ext !== 'png' &&
                        ext !== 'bmp' &&
                        ext !== 'svg'
                      "
                        color="success"
                        shape="pill"
                        v-on:click="viewRegDoc()"
                    >
                      {{ $lang.profile_verification.form.doc }}
                    </CButton>
                    <img
                        v-else
                        @click="open(registeredDoc)"
                        :src="registeredDoc"
                        alt="Registered Doc"
                        class="imageDimenstion"
                    />
                  </div>
                </CCol>
              </CRow>


            </div>
            <CRow>
              <CCol sm="6" md="4" lg="6" v-if="profile_verification.type==='College'">
                <p><b>Identification Documents (User)</b></p>
                <table class="table table-striped table-bordered table-hover">
                  <thead>
                  <th>Document Type</th>
                  <th>Number </th>
                  <th>Name </th>
                  <th>Actions</th>
                  </thead>
                  <tbody>
                    <tr v-for="userDocs in profile_verification.identifications.user"
                        :key="userDocs.id">
                        <td>{{ userDocs.title }} </td>
                        <td>{{ userDocs.value }}</td>
                        <td>{{ userDocs.name }}</td>
                        <td>
                          <CButton color="dark" size="sm" v-if="userDocs.status===0" @click="checkIdentity(userDocs.id,userDocs.value,'user')">
                            Check
                          </CButton>
                          <CButton color="info" size="sm" v-if="userDocs.status===0" @click="verifyIdentity(userDocs.id)">
                            Verify
                          </CButton>
                          <CButton color="success" size="sm" v-if="userDocs.status===1">
                            Verified
                          </CButton>
                        </td>
                  </tr>
                  <tr>
                    <td colspan="4" v-if="identificationOfUser.success=false" v-show="identificationOfUser.message">Rejection Message : {{ identificationOfUser.message }}</td>
                    <td colspan="4" v-if="identificationOfUser.success=true" v-show="identificationOfUser.message">Full Name : {{ identificationOfUser.message }}</td>
                  </tr>
                  </tbody>
                  </table>
              </CCol>
              <!--Company-->
              <CCol sm="6" md="4" lg="6">
                <p><b>Identification Documents ({{ profile_verification.type }})</b></p>
                  <table class="table table-striped table-bordered table-hover">
                  <thead>
                  <th>Document Type</th>
                  <th>Number </th>
                  <th>Name </th>
                  <th>Actions</th>
                  </thead>
                  <tbody>
                    <tr v-for="companyDocs in profile_verification.identifications.company"
                        :key="companyDocs.id">
                        <td>{{ companyDocs.title }} </td>
                        <td>{{ companyDocs.value }}</td>
                        <td>{{ companyDocs.name }}</td>
                        <td>

                          <CButton color="dark" size="sm" v-if="companyDocs.status===0" @click="checkIdentity(companyDocs.id,companyDocs.value,'College')">
                            Check
                          </CButton>

                          <CButton color="info" size="sm" v-if="companyDocs.status===0" @click="verifyIdentity(companyDocs.id)">
                            Verify
                          </CButton>

                          <CButton color="success" size="sm" v-if="companyDocs.status===1">
                            Verified
                          </CButton>
                        </td>
                  </tr>
                  <tr>

                    <td colspan="4" v-if="identificationOfCompany.success=false" v-show="identificationOfCompany.message">Rejection Message : {{ identificationOfCompany.message }}</td>
                    <td colspan="4" v-if="identificationOfCompany.success=true" v-show="identificationOfCompany.message">Full Name : {{ identificationOfCompany.message }}</td>

                  </tr>
                  </tbody>
                  </table>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6" md="4" lg="12">
                <p><b>Social Links</b></p>
                  <table class="table table-striped table-bordered table-hover">
                  <thead>
                    <th>Plateform</th>
                    <th>Link</th>
                    <th>Actions</th>
                  </thead>
                  <tbody>
                    <tr
                        v-for="(links, index) in socialLinks"
                        :key="links.id"
                        class="doc-box">
                        <td>{{ links.platform }} </td>
                        <td>
                          <span v-if="links.link">
                           {{ links.link }}
                          </span>
                          <span v-else>
                            Not added yet
                          </span>

                        </td>
                        <td>
<!--                          <CButton color="success" size="sm">-->
                          <!--                            <a :href="links.link" target="_blank" >View</a>-->
                          <!--                          </CButton>-->
                          <CButton color="info" v-show="links.link" size="sm" v-if="links.status===0"
                                   @click="verifySocialLinks(links.id)">
                            Verify
                          </CButton>


                          <CButton color="success" size="sm" v-if="links.status===1">
                            Verified
                          </CButton>
                        </td>
                  </tr>
                  </tbody>
                  </table>
              </CCol>
            </CRow>


            <CRow v-if="profile_verification.type === 'College'">
                <CCol sm="6" md="4" lg="12">
                  <div class="form-group" id="people">
                    <label>
                    {{ $lang.profile_verification.form.vdocuments }}
                    </label>
                    <div class="verify-docs">
                      <div
                          v-for="(docs, index) in verifyDocs"
                          :key="docs.id"
                          class="doc-box"
                      >
                        <div class="card card-file media">
                          <!-- <div class="card-file-thumb">
                            <i class="fa fa-file-word-o"></i>
                          </div> -->
                          <div class="card-body text-center">

                            <div v-if="docs.type === 'image'">
                              <img
                                  @click="open(docs.url)"
                                  alt="Image"
                                  :src="docs.url"
                                  class="imageDimenstion"
                              />
                            </div>
                            <div v-else-if="docs.type === 'pdf'">
                              <img
                                  :src="docs.defaultImage"
                                  alt="Default Image"
                                  v-on:click="viewPdf(docs.id)"
                                  class="imageDimenstion mx-auto d-block"
                              />
                            </div>
                            <div v-else-if="docs.type === 'doc'">
                              <img
                                  :src="docs.defaultImage"
                                  alt="Default Image"
                                  v-on:click="viewDoc(docs.id)"
                                  class="imageDimenstion mx-auto d-block"
                              />
                            </div>
                            <div v-else-if="docs.type === 'xls'">
                              <img
                                  :src="docs.defaultImage"
                                  alt="Default Image"
                                  v-on:click="viewXls(docs.id)"
                                  class="imageDimenstion mx-auto d-block"
                              />
                            </div>
                            <div v-else>
                              <img
                                  :src="docs.defaultImage"
                                  alt="Default Image"
                                  v-on:click="viewDoc(docs.id)"
                                  class="imageDimenstion mx-auto d-block"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CCol>
              </CRow>
          <CRow>
                <CCol sm="6" md="4" lg="12">
                  <div class="form-group" id="people">
                    <label>
                    {{ $lang.profile_verification.form.vuserdocuments }}
                    </label>
                    <div class="verify-docs">
                      <div
                          v-for="(docs, index) in verifyUserDocs"
                          :key="docs.id"
                          class="doc-box"
                      >

                        <div class="card card-file media">
                          <!-- <div class="card-file-thumb">
                            <i class="fa fa-file-word-o"></i>
                          </div> -->
                          <div class="card-body text-center">
                            <div v-if="docs.type === 'image'">
                              <img
                                  @click="open(docs.url)"
                                  alt="Image"
                                  :src="docs.url"
                                  class="imageDimenstion"
                              />
                            </div>
                            <div v-else-if="docs.type === 'pdf'">
                              <img
                                  :src="docs.defaultImage"
                                  alt="Default Image"
                                  v-on:click="viewPdf(docs.id)"
                                  class="imageDimenstion mx-auto d-block"
                              />
                            </div>
                            <div v-else-if="docs.type === 'doc'">
                              <img
                                  :src="docs.defaultImage"
                                  alt="Default Image"
                                  v-on:click="viewDoc(docs.id)"
                                  class="imageDimenstion mx-auto d-block"
                              />
                            </div>
                            <div v-else-if="docs.type === 'xls'">
                              <img
                                  :src="docs.defaultImage"
                                  alt="Default Image"
                                  v-on:click="viewXls(docs.id)"
                                  class="imageDimenstion mx-auto d-block"
                              />
                            </div>
                            <div v-else>
                              <img
                                  :src="docs.defaultImage"
                                  alt="Default Image"
                                  v-on:click="viewDoc(docs.id)"
                                  class="imageDimenstion mx-auto d-block"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CCol>
              </CRow>
            <br/>
            <div class="verified">
              <div class="form-group">
                <input
                    type="checkbox"
                    :placeholder="$lang.business_traveller.form.userVerified"
                    @change="changedUserVerified"
                    v-model="profile_verification.userVerified"
                />
                <label class="form__label">{{ $lang.profile_verification.form.userVerified }} </label>
              </div>
              <div class="form-group" v-if="profile_verification.type === 'College'">
                <input type="checkbox" :value="1" @change="changedCompanyVerified" v-model="profile_verification.companyVerified"/>
                <label class="form__label">{{ $lang.profile_verification.form.companyVerified }} </label>
              </div>
              <div class="form-group" v-if="profile_verification.type === 'College'">
                <input
                    type="checkbox"
                    :placeholder="$lang.business_traveller.form.domainVerified"
                    :value="1"
                    @change="changedDomainVerified"
                    v-model="profile_verification.domainVerified"
                />
                <label class="form__label">{{ $lang.profile_verification.form.domainVerified }} </label>
              </div>
              <div class="form-group" v-if="profile_verification.type === 'College'">
                <input
                    type="checkbox"
                    :value="1"
                    @change="changedJobTitleVerified"
                    v-model="profile_verification.jobTitleVerified"/>
                <label class="form__label">{{ $lang.profile_verification.form.jobTitleVerified }} </label>
              </div>
            </div>
            <div class="form-group" v-if="profile_verification.status.value!='Verified'">
              <label class="form__label"
              >{{ $lang.profile_verification.form.status }}
                <required_span/>
              </label>

              <v-select
                  :options="statusOptions"
                  @option:selected="statusChange"
                  v-model="profile_verification.status"
              ></v-select>
              <small
                  class="error"
                  v-if="
                  $v.profile_verification.status.$error &&
                  !$v.profile_verification.status.required
                "
              >{{
                  $lang.profile_verification.validation.required.status
                }}</small
              >
            </div>

            <div v-if="profile_verification.status.value==='rejected'">
              <c-textarea
                  v-model="profile_verification.reason"
                  :rows="5"
                  :cols="10"
                  placeholder="Add reason for rejected profile verification."
              ></c-textarea>
              <small
                  class="error"
                  v-if="$v.profile_verification.reason.$error && !$v.profile_verification.reason.required"
              >{{
                  $lang.profile_verification.validation.required.reason
                }}</small
              >
            </div>




          <div v-if="profile_verification.status.value!='Verified'" >
            <CButton
                type="submit"

                color="primary"
                :disabled="submitted"
            >
              {{ $lang.buttons.general.crud.submit }}
            </CButton>
            <CButton color="danger" @click="cancel">
              {{ $lang.buttons.general.crud.cancel }}
            </CButton>
          </div>

        </CForm>
      </span>
      <CCard v-else>
        <CRow>
          <CCol>
            <center>
              <div class="">
                {{ $lang.profile_verification.info.verification_not_added_yet }}
              </div>
            </center>
          </CCol>
        </CRow>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import { profileVerifications, users, verificationStatus, profile, PANVERIFICATION } from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import {required, requiredIf} from "vuelidate/lib/validators";
import required_span from "/src/components/layouts/general/required-span";
import VueToasted from "vue-toasted";
import "viewerjs/dist/viewer.css";
import moment from "moment";
function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

// Vue.use(VueViewer)
Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "ProfileVerification",
  mixins: [Mixin],
  components: {
    required_span
  },
  data() {
    return {
      uinApiMessageColor: {
        color: "green",
      },
      cinApiMessageColor: {
        color: "green",
      },
      existResponse: false,
      submitted: false,
      largeModal: false,
      Disabled: false,
      meetingIsDisabled: false,
      color: 'success',
      regDocUrl: "",
      ext: "",
      userId: "",
      viewImage: "",
      err_msg: "",
      profileImage: "",
      registeredDoc: "",
      dismissCountDown: 0,
      profileVerificationResponse :[],
      userOptions: [],
      companyOptions: [],
      statusOptions: [],
      selectRejectType: [],
      verifyDocs: [],
      verifyUserDocs: [],
      socialLinks: [],
      identificationOfUser:[],
      identificationOfCompany:[],
      self: this,
      module: profileVerifications,
      moduleProfile: profile,
      moduleUser: users,
      moduleIdentification: PANVERIFICATION,
      moduleVerificationStatus: verificationStatus,
      profile_verification: {
        name: "",
        scheduleVideoMeeting: "",
        address: "",
        registeredNumber: "",
        regDoc: "",
        status: "pending",
        companyIdentificationNumber: "",
        companyIdentificationName: "",
        userIdentificationNumber: "",
        userIdentificationName: "",
        asBusiness: "",
        businessTypeId: "",
        businessType: "",
        cinIsVerified: "",
        cinApiMessage: "",
        uinIsVerified: "",
        uinApiMessage: "",
        userVerified: 0,
        companyVerified: 0,
        domainVerified: 0,
        jobTitleVerified: 0,
        type: "",
        companyId: "",
        identifications: [],
        meetingStatus: "",
        reason: "",
      },
    };
  },
  validations: {
    profile_verification: {
      status: {
        required,
      },
      reason: {
        requiredIf: requiredIf(function () {
          return this.profile_verification.status.value === 'rejected';

        }),
      },
    },
  },
  mounted() {
    store.commit("showLoader", false); // Loader Off
    let self = this;
    self.dismissCountDown = 0;
    const id = this.$route.params.id;
    const type = 'College';
    self.dismissCountDown = 0;
    self.editId = id;
    axios.get(this.listUrlApi(this.moduleVerificationStatus))
        .then((response) => {
          self.subIndustryOptions = [];
          response.data.data.map(function (value, key) {
            self.statusOptions.push({value: value.value, label: value.label});
          });
        });
    self.profile_verification.status = {
      value: "pending",
      label: "Pending",
    };
    self.selectRejectType.push(
        {value: '', label: 'Select'},
        {value: '1', label: 'Profile'},
        // {value: '0', label: 'Job Title Proof'}
    );
    axios.get('/users/profiles/verification/view/'+id+'/'+type).then((response) => {
      // eslint-disable-next-line no-empty
      if (response.data.data) {
        let responseData = response.data.data;
        self.profileVerificationResponse = responseData;
        self.profile_verification.identifications = responseData.identifications;
        self.existResponse = responseData.verifyUserDocs.length;
        self.verifyDocs = responseData.verifyDocs;
        self.verifyUserDocs = responseData.verifyUserDocs;
        self.socialLinks = responseData.socialLinks;
        self.profile_verification.name = responseData.name;
        self.ext = responseData.ext; //redDoc ext
        self.profile_verification.address = responseData.address;
        self.profile_verification.type = responseData.type;
        self.profile_verification.companyId = responseData.companyId;
        self.profile_verification.meetingStatus = responseData.meetingStatus;
        self.profile_verification.scheduleVideoMeeting = responseData.scheduleVideoMeeting;
        if (self.profile_verification.meetingStatus === 1) {
          self.color = 'primary';
          self.meetingIsDisabled = true;
        } else {
          self.color = 'success';
          self.meetingIsDisabled = false;
        }
        self.profile_verification.registeredNumber = responseData.registeredNumber;
        self.profile_verification.regDate = responseData.regDate; //  moment(responseData.regDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
        self.profile_verification.userVerified = parseInt(responseData.userVerified);
        self.profile_verification.companyVerified = parseInt(responseData.companyVerified);
        self.profile_verification.domainVerified = parseInt(responseData.domainVerified);
        self.registeredDoc = responseData.regDoc;
        self.userId = responseData.userId;
        self.profile_verification.businessTypeId = responseData.businessTypeId;
        self.profile_verification.cinIsVerified = responseData.cinIsVerified;
        if (responseData.cinIsVerified === 0) {
          self.cinApiMessageColor.color = "red";
        }
        self.profile_verification.uinIsVerified = responseData.uinIsVerified;
        if (responseData.uinIsVerified === 0) {
          self.uinApiMessageColor.color = "red";
        }
        self.profile_verification.cinApiMessage = responseData.cinApiMessage;
        self.profile_verification.uinApiMessage = responseData.uinApiMessage;
        const status = responseData.status;
        self.profile_verification.status = {
          value: status,
          label: status.charAt(0).toUpperCase() + status.slice(1),
        };
        self.profile_verification.businessType = responseData.businessType;
        self.profile_verification.asBusiness = responseData.asBusiness;
        self.profile_verification.company = responseData.company;
        self.profile_verification.companyIdentificationNumber = responseData.companyIdentificationNumber;
        self.profile_verification.companyIdentificationName = responseData.companyUidName;
        self.profile_verification.userIdentificationNumber = responseData.userIdentificationNumber;
        self.profile_verification.userIdentificationName = responseData.userUidName;
      }
    });
  },
  methods: {
    sendCalenderLink(companyId) {
      let self = this;
      self.meetingIsDisabled = true;
      axios.get('users/profiles/verification/sendMeetingLink/' + companyId+'/College').then((response) => {
        if (response.data.status === 200) {
          self.err_msg = response.data.message;
          self.dismissCountDown = 10;
          self.meetingIsDisabled = true;
          self.color = 'success';
        } else {
          self.meetingIsDisabled = false;
          self.err_msg = response.data.message;
          self.dismissCountDown = 10;
          self.color = 'danger';
        }
      });
    },
    checkIdentity(verifyId,value,whose) {
      let self = this;
      const postData = {
        value:value,
        id:verifyId,
        whose:whose,
        userId:this.$route.params.userId
      }
      axios
          .post('users/profiles/verification/check',postData)
          .then(function (response) {
            if (response.status === 200) {
              let responseData = response.data.data;
              if(responseData.whose=='user') {
                self.identificationOfUser = responseData;
              } else if(responseData.whose=='College'){
                self.identificationOfCompany = responseData;
              }
            } else {
              self.err_msg = response.message;
              self.dismissCountDown = 10;
            }
          })
          .catch(function (error) {
          });
    },
    verifyIdentity(verifyId) {
      let self = this;
      const id = this.$route.params.id;
      axios
          .post('users/profiles/verification/verify-identity-docs/'+verifyId)
          .then(function (response) {
            if (response.status === 200) {
              axios.get(self.viewUrlApi(self.module, id)).then((response) => {
                if (response.data.status === 200) {
                  let responseData = response.data.data;
                  self.profile_verification.identifications = responseData.identifications;
                }
              });
            } else {
              self.err_msg = response.data.message;
              self.dismissCountDown = 10;
            }
          })
          .catch(function (error) {

          });
    },

    verifySocialLinks(verifyId) {
      let self = this;
      const id = this.$route.params.id;
      axios
          .post('users/profiles/social-links/update/' + verifyId)
          .then(function (response) {
            if (response.status === 200) {
              axios.get(self.viewUrlApi(self.module, id)).then((response) => {
                if (response.data.status === 200) {
                  self.socialLinks = response.data.data.socialLinks;
                }
              });
            } else {
              self.err_msg = response.data.message;
              self.dismissCountDown = 10;
            }
          })
          .catch(function (error) {
          });
    },


    hide() {
      // alert("sdf");
    },
    open(url) {
      let self = this;
      self.viewImage = url;
      //  fancyBox(e.target,this.images);
      self.largeModal = true;
    },

    verifyIdentificationNumber(userId, idNumber, idName, isCompanyNumber) {
      setTimeout(() => {
        let self = this;
        const postData = {
          uIdNumber: idNumber,
          uIdName: idName,
          userId: userId,
          isCompanyNumber: isCompanyNumber,
        };
        axios
            .post(this.createUrlWeb(this.moduleIdentification), postData)
            .then(function (response) {
              if (response.data.code === 200) {
                const responseData = response.data.data;

                self.profile_verification.cinIsVerified =
                    responseData.cinIsVerified;

                self.profile_verification.cinApiMessage =
                    responseData.cinApiMessage;
                self.profile_verification.uinApiMessage =
                    responseData.uinApiMessage;

                if (self.profile_verification.cinIsVerified === 0) {
                  self.cinApiMessageColor.color = "red";
                } else {
                  self.cinApiMessageColor.color = "green";
                }
                self.profile_verification.uinIsVerified =
                    responseData.uinIsVerified;

                if (self.profile_verification.uinIsVerified === 0) {
                  self.uinApiMessageColor.color = "red";
                } else {
                  self.uinApiMessageColor.color = "green";
                }
              } else {
                self.err_msg = response.data.response.message;
                self.dismissCountDown = 10;
              }
            })
            .catch(function (error) {
            });
      }, 500);
    },

    viewRegDoc() {
      let self = this;
      const userId = self.$route.params.userId;
      let routeData = this.$router.resolve({path: `/viewer/reg/${userId}`});
      window.open(routeData.href, "_blank");
    },

    viewPdf(id) {
      let routeData = this.$router.resolve({path: `/viewer/pdf/${id}`});
      window.open(routeData.href, "_blank");
    },
    viewDoc(id) {
      let routeData = this.$router.resolve({path: `/viewer/doc/${id}`});
      window.open(routeData.href, "_blank");
    },
    viewXls(id) {
      let routeData = this.$router.resolve({path: `/viewer/xls/${id}`});
      window.open(routeData.href, "_blank");
    },

    documentsIndex: function (index) {
      return index + 1;
    },
    getFormat: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    changedUserVerified(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.profile_verification.userVerified = 1;
      } else {
        self.profile_verification.userVerified = 0;
      }
    },

    changedCompanyVerified(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.profile_verification.companyVerified = 1;
      } else {
        self.profile_verification.companyVerified = 0;
      }
    },
    changedDomainVerified(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.profile_verification.domainVerified = 1;
      } else {
        self.profile_verification.domainVerified = 0;
      }
    },
    statusChange() {
      let self = this, vStatus;
      if (self.profile_verification.status.value !== 'verified') {
        vStatus = 0;
      } else {
        vStatus = 1;
      }
      self.profile_verification.userVerified = vStatus;
      self.profile_verification.domainVerified = vStatus;
      self.profile_verification.companyVerified = vStatus;
      self.profile_verification.jobTitleVerified = vStatus;
    },
    changedJobTitleVerified(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.profile_verification.jobTitleVerified = 1;
      } else {
        self.profile_verification.jobTitleVerified = 0;
      }
    },

    sendAlert() {
      this.$root.$emit("alert", "test1", "test2");
    },
    onSubmit() {

      let self = this;
      this.$v.profile_verification.$touch();
      if (this.$v.profile_verification.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";

      } else {
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";
        setTimeout(() => {
          let self = this;
          let username, status;
          //    if(self.profile_verification.user.value!="") {
          //        username  = self.profile_verification.user.value;
          //    }
          username = self.$route.params.id;
          if (self.profile_verification.status.value !== "") {
            status = self.profile_verification.status.value;
          }

          const postData = {
            userId: username,
            type: self.profile_verification.type,
            registeredNumber: self.profile_verification.registeredNumber,
            name: self.profile_verification.name,
            address: self.profile_verification.address,
            regDate: self.profile_verification.regDate,
            userVerified: self.profile_verification.userVerified,
            companyVerified: self.profile_verification.companyVerified,
            domainVerified: self.profile_verification.domainVerified,
            jobTitleVerified: self.profile_verification.jobTitleVerified,
            status: status,
            reason: self.profile_verification.reason
          };

          axios
              .post(this.updateUrlweb(this.module, self.$route.params.userId), postData)
              .then(function (response) {
                self.submitted = false; //Enable Button
                if (response.data.code === 200) {
                  localStorage.setItem("showAlert", "This is my alert");
                  self.sendAlert();
                  self.$router.push({path: "/college"});
                } else {
                  self.err_msg = response.data.response.message;
                  self.dismissCountDown = 10;
                }
              })
              .catch(function (error) {
                let data;
                if (error.response.data.error) {
                  data = error.response.data.error.toString();
                } else {
                  data = error.response.data.message;
                }
                self.err_msg = data;
                self.color = 'danger';
                self.dismissCountDown = 10;
                self.submitted = false; //Enable Button
              });
        }, 500);
      }
    },
    cancel() {
      this.usersOpened
          ? this.$router.go(-1)
          : this.$router.push({path: "/college"});
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.imageDimenstion {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.hasError label {
  color: red;
}

.imageFormat {
  width: 100%;
}
</style>
