<template>
  <CRow>
    <CCol col="12">
      <div class="row">
        <CCol sm="12" class="align-self-center">
          <div class="text-sm-right mt-3 mt-sm-0">
            <CButtonGroup class="file-export">
              <CButton color="secondary" disabled>
                {{ $lang.buttons.export.title }}
              </CButton>
              <CButton
                  color="danger"
                  v-c-tooltip="$lang.buttons.export.pdf"
                  v-on:click="exports(self, 'pdf', module, 'College Employer',self.editId)">
                <i class="fas fa-file-pdf"></i>
              </CButton>
              <VueJsonToCsv
                  class="btn btn-success"
                  v-c-tooltip="$lang.buttons.export.excel"
                  :json-data="json_data"
                  :labels="json_label"
                  :csv-title="json_title">
                <i class="fas fa-file-excel"></i>
              </VueJsonToCsv>
            </CButtonGroup>
          </div>
        </CCol>
      </div>
      <v-server-table
          :columns="columns"
          :url="listUrl"
          :options="options"
          ref="myTable"
          @loaded="exports(self, 'Excel', module, 'College Employer',self.editId)">
        <template #actions="data">
          <CButton
              color="warning"
              v-c-tooltip="$lang.student.crud.view"
              v-on:click="viewTableRow(data.row.id)"
          >
            <i class="fas fa-eye"></i>
          </CButton>
        </template>
      </v-server-table>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import {college} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import {ServerTable} from "vue-tables-2";
import VueJsonToCsv from "vue-json-to-csv";
Vue.use(ServerTable, {}, false);
export default {
  name: "College Employer",
  mixins: [Mixin],
  components: {
    VueJsonToCsv,
  },
  data() {
    return {
      json_data: [],
      json_label: {},
      json_title: "",
      self: this,
      listUrl: "",
      editId: "",
      module: college,
      columns: ["company","email","website","address1","actions"],
      data: [],
      options: {
        headings: {
          company: this.$lang.collegeEmployer.table.company,
          email: this.$lang.collegeEmployer.table.email,
          website: this.$lang.collegeEmployer.table.website,
          address1: this.$lang.collegeEmployer.table.address1,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: [],
        sortable: [],
        filterable: [],
      },
    };
  },
  created()
  {
    let self = this;
    const id = this.$route.params.id;
    self.editId = id;
    self.listUrl = "/college/employers/" + id;
  },
  methods: {
    viewTableRow(item) {
      this.$router.push({path: `/college/employer/view/${item}`});
    },
  },
};
</script>
