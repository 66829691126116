<template>
  <CRow>
    <CCol col="12">
      <div class="row">
        <CCol sm="12" class="align-self-center">
          <div class="text-sm-right mt-3 mt-sm-0">
            <CButtonGroup class="file-export">
              <CButton color="secondary" disabled>
                {{ $lang.buttons.export.title }}
              </CButton>
              <CButton color="danger" v-c-tooltip="$lang.buttons.export.pdf" v-on:click="exports(self, 'pdf', module, 'College Member',self.editId)">
                <i class="fas fa-file-pdf"></i>
              </CButton>
              <VueJsonToCsv class="btn btn-success" v-c-tooltip="$lang.buttons.export.excel" :json-data="json_data" :labels="json_label" :csv-title="json_title">
                <i class="fas fa-file-excel"></i>
              </VueJsonToCsv>
            </CButtonGroup>
          </div>
        </CCol>
      </div>
      <v-server-table :columns="columns" :url="listUrl" :options="options" ref="myTable" @loaded="exports(self, 'Excel', module, 'College Member',self.editId)">
        <template #username="data">
          {{ data.row.countryCode }} {{ data.row.username }}
        </template>
        <template #actions="data">
          <CButton color="warning" v-c-tooltip="$lang.student.crud.view" v-on:click="viewTableRow(data.row.id,data.row.role)">
            <i class="fas fa-eye"></i>
          </CButton>
          <CButton color="danger" v-c-tooltip="$lang.buttons.general.crud.deactive" v-on:click="deactiveRow(data.row.id)" v-if="data.row.status === 1">
            <i class="fas fa-user-slash"></i>
          </CButton>
          <CButton color="warning" v-c-tooltip="$lang.buttons.general.crud.active" v-on:click="deactiveRow(data.row.id)" v-if="data.row.status === 0">
            <i class="fas fa-user"></i>
          </CButton>
        </template>
      </v-server-table>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import {college} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import "vue-simple-range-slider/dist/vueSimpleRangeSlider.css";
import VueJsonToCsv from "vue-json-to-csv";
import {serverBus} from "../../../main";
Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
export default {
  name: "College",
  mixins: [Mixin],
  components: {
    VueJsonToCsv,
  },
  data() {
    return {
      json_data: [],
      json_label: {},
      json_title: "",
      self: this,
      listUrl: "",
      editId: "",
      module: college,
      columns: ["firstName","email","role","userStatus","username","actions"],
      data: [],
      options: {
        headings: {
          firstName: this.$lang.collegeMember.table.firstName,
          email: this.$lang.collegeMember.table.email,
          role: this.$lang.collegeMember.table.role,
          userStatus: this.$lang.collegeMember.table.userStatus,
          username: this.$lang.collegeMember.table.username,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: [],
        sortable: [],
        filterable: [],
      },
    };
  },
  created()
  {
    let self = this;
    const id = this.$route.params.id;
    self.editId = id;
    self.listUrl = "/college/members/" + id;
  },
  methods: {
    viewTableRow(item,role) {
      const id = this.$route.params.id;
      serverBus.$emit('send_college_role', role);
      this.$router.push({path: `/college/view/`+id+'/'+item});
    },
    deactiveRow(item) {
      this.$swal({
        title: this.$lang.common.deactive_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.deactive_swal.yes_delete,
        cancelButtonText: this.$lang.common.deactive_swal.cancel,
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios.post('users/child/deactive/' + item).then((response) => {
            if (response.data.code == 200) {
              self.$refs.myTable.refresh();
              self.alertMessage = response.data.message;
              self.dismissCountDownS = 10;
            }
          });
        }
      });
    },
  },
};
</script>
