<template>
  <div class="detail-div">
    <v-server-table :columns="columns" :url="listUrl" :options="options" ref="myTable" @loaded="exports(self, 'Excel', module, 'Groups',userId)">
      <template #actions="data">
        <CButton color="primary" v-c-tooltip="$lang.buttons.general.view" v-on:click="viewGA(data.row.uuid,data.row.userId,data.row.title)">
          <i class="fas fa-eye"></i>
        </CButton>
      </template>
    </v-server-table>
  </div>
</template>
<script>
import {branch} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";

export default {
  name: "GroupTab",
  mixins: [Mixin],
  data() {
    return {
      listUrl: '/users/groups/list',
      self: this,
      userName: '',
      columns: ["shortName", "title", "subTitle", "actions"],
      module: branch,
      options: {
        headings: {
          shortName: this.$lang.profile.table.group.shortName,
          name: this.$lang.profile.table.group.group,
          subTitle: this.$lang.profile.table.group.subTitle,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: [
          "shortName",
          "title",
        ],
        sortable: ["title"],
        filterable: ["title"],
      },
    };
  },
  methods: {
    viewGA(uuid, userId, title) {
      let self = this;
      self.userId = this.$route.params.userId;
      localStorage.setItem('groupTitle', title);
      this.$router.push({path: `/college/my-gac/${(uuid)}/${(userId)}`})
    },
  },
  created() {
    let self = this;
    self.dismissCountDown = 0;
    self.userId = this.$route.params.userId;
    self.listUrl = self.listUrl + "/" + self.userId;
    self.dismissCountDown = 0;
  },
};
</script>
