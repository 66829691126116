<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          <CCol>
            <strong>{{ collegeName }}</strong>
          </CCol>
        </CCardHeader>
        <CCardBody>
          <CTabs add-tab-classes="mt-1">
            <span @click="selected = 'CollegeView'">
              <CTab>
                <template slot="title">
                  {{ $lang.college.crud.detail }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'CollegeStream'" v-if="role == 'Admin'">
              <CTab>
                <template slot="title">
                  {{ $lang.college.crud.stream }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'CollegeProgramme'" v-if="role == 'Admin'">
              <CTab>
                <template slot="title">
                  {{ $lang.college.crud.programme }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'CollegeDiscipline'" v-if="role == 'Admin'">
              <CTab>
                <template slot="title">
                  {{ $lang.college.crud.discipline }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'CollegeStudent'" v-if="role == 'Admin'">
              <CTab>
                <template slot="title">
                  {{ $lang.college.crud.student }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'CollegeMember'">
              <CTab>
                <template slot="title">
                  {{ $lang.college.crud.member }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'ProfileVerification'" v-if="role == 'Admin'">
              <CTab>
                <template slot="title">
                  {{ $lang.college.crud.verification }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'CollegeEmployer'" v-if="role == 'Admin'">
              <CTab>
                <template slot="title">
                  {{ $lang.college.crud.employer }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'PlanDetail'" v-if="role == 'Admin'">
              <CTab>
                <template slot="title">
                  {{ $lang.college.crud.plan }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'GroupChangeList'">
              <CTab>
                <template slot="title">
                 {{ $lang.college.crud.jobDesignationRequests }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'MeetingList'">
              <CTab>
                <template slot="title">
                 {{ $lang.college.crud.meetingList }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'Groups'">
              <CTab>
                <template slot="title">
                 {{ $lang.college.crud.groups }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'Branches'">
              <CTab>
                <template slot="title">
                 {{ $lang.college.crud.branches }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'ProfileUpdateRequest'">
              <CTab>
                <template slot="title">
                 {{ $lang.college.crud.profileUpdateRequest }}
                </template>
              </CTab>
            </span>
          </CTabs>
          <component :is="selected" @changeSelected="getTab($event)" style="margin-top: 20px"></component>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import {Mixin} from "/src/mixins/index.js";
import CollegeView from "./tab/CollegeView";
import CollegeStream from "./tab/CollegeStream";
import CollegeEmployer from "./tab/CollegeEmployer";
import PlanDetail from "./tab/PlanDetail";
import GroupChangeList from "./tab/GroupChangeList";
import Branches from "./tab/Branches";
import CollegeProgramme from "./tab/CollegeProgramme";
import ProfileUpdateRequest from "./tab/ProfileUpdateRequest";
import CollegeDiscipline from "./tab/CollegeDiscipline";
import CollegeStudent from "./tab/CollegeStudent";
import ProfileVerification from "./tab/ProfileVerification";
import CollegeMember from "./tab/CollegeMember";
import MeetingList from "./tab/MeetingList";
import Groups from "./tab/Groups";
import BackToList from "/src/views/backToList.vue";
import store from "../../store/store";
import {serverBus} from "../../main";

export default {
  name: "College View",
  mixins: [Mixin],
  components: {
    CollegeView,
    CollegeStream,
    CollegeProgramme,
    CollegeDiscipline,
    CollegeStudent,
    CollegeMember,
    ProfileVerification,
    CollegeEmployer,
    PlanDetail,
    BackToList,
    MeetingList,
    Groups,
    Branches,
    ProfileUpdateRequest,
    GroupChangeList
  },
  data() {
    return {
      userId: "",
      tabs: ["CollegeView","CollegeStream","CollegeProgramme","CollegeDiscipline","CollegeStudent","CollegeMember","ProfileVerification","CollegeEmployer","PlanDetail","GroupChangeList","MeetingList","Groups","Branches","ProfileUpdateRequest"],
      selected: "CollegeView",
      currentId: "",
      backToListFlag: "",
      collegeName: "",
      role: "Admin",
    };
  },
  mounted() {
    let self = this;
    const id = this.$route.params.id;
    self.userId = this.$route.params.userId;
    self.currentId = id;
    axios.get('/college/'+id)
        .then((response) => {
          let college = response.data.data;
          self.collegeName = college.name;
          store.commit("showLoader", false); // Loader Off
        })
        .catch(function (error) {
          store.commit("showLoader", false); // Loader Off
        });
    serverBus.$on("send_college_role", function (role) {
      self.role = role;
    });
  },
  methods: {
    backToList(userId) {
      if (userId != null) {
        this.$router.push({path: "/profile/all/" + userId});
      }
    },
    getTab(tab) {
      let self = this;
      self.selected = tab;
    },
  },
};
</script>
<style>
div .error {
  color: red;
}
.hasError label {
  color: red;
}
</style>