<template>
  <div class="detail-div">

    <CCardHeader>
      <div class="row">
        <CCol sm="6" class="align-self-left">
          <strong><h6><u>Current Plan Details</u></h6></strong>
        </CCol>
        <CCol sm="6" class="align-self-right">
          <CButton
              color="danger"
              v-c-tooltip="$lang.common.general.edit_permission"
              v-on:click="editPermission(activePlan.paymentId)"
              style="float: right"
          >
            <i class="fas fa-user-lock"></i>
          </CButton>
        </CCol>
      </div>
    </CCardHeader>

    <CCardBody>
      <div v-if="Object.keys(activePlan).length > 0">
        <CCol md="12">
          <CRow>
            <CCol md="3" class="text-left">
              Plan Name: {{ activePlan.name }}
            </CCol>
            <CCol md="3" class="text-left">
              Plan Type: {{ activePlan.durationType }}
            </CCol>
            <CCol md="3" class="text-left">
              Duration: {{ activePlan.duration }}
              {{
                activePlan.durationType === "Monthly"
                    ? "Month"
                    : activePlan.durationType === "Yearly"
                        ? "Year"
                        : "Days"
              }}
            </CCol>
            <CCol md="3" class="text-left">
              Purchase Date: {{ getDateWithFormat(activePlan.date) }}
            </CCol>
            <CCol md="3" class="text-left">
              Start Date: {{ getDateWithFormat(activePlan.startDate) }}
            </CCol>
            <CCol md="3" class="text-left">
              End Date: {{ getDateWithFormat(activePlan.endDate) }}
            </CCol>
            <CCol md="3" class="text-left">
              Amount: {{ activePlan.amount }}
            </CCol>
            <CCol md="3" class="text-left">
              Discount: {{ activePlan.discountValue }}
            </CCol>
            <CCol md="3" class="text-left">
              Total Amount: {{ activePlan.totalAmount }}
            </CCol>
            <CCol md="3" class="text-left">
              Plan Members : {{activePlan.totalUsers}}
            </CCol>

            <CCol md="3" class="text-left" v-show="activePlan.invoiceUuid">
              <a color="primary" size="sm" _target="blank" :href="activePlan.invoiceUrl">View Invoice</a>
            </CCol>
            <CCol md="6" class="text-left">
              Profile Status : {{ activePlan.statusMessage }}
            </CCol>
          </CRow>
        </CCol>
        <hr/>
      </div>
      <div v-if="Object.keys(upcomingPlan).length > 0">
        <h6><u>Upcoming Plan Details</u></h6>
        <br/>
        <CCol md="12">
          <CRow>
            <CCol md="3" class="text-left">
              Plan Name: {{ upcomingPlan.name }}
            </CCol>
            <CCol md="3" class="text-left">
              Plan Type: {{ upcomingPlan.durationType }}
            </CCol>
            <CCol md="3" class="text-left">
              Duration: {{ upcomingPlan.duration }}
              {{
                upcomingPlan.durationType === "Monthly"
                    ? "Month"
                    : upcomingPlan.durationType === "Yearly"
                        ? "Year"
                        : "Days"
              }}
            </CCol>
            <CCol md="3" class="text-left">
              Purchase Date: {{ getDateWithFormat(upcomingPlan.date) }}
            </CCol>
            <CCol md="3" class="text-left">
              Start Date: {{ getDateWithFormat(upcomingPlan.startDate) }}
            </CCol>
            <CCol md="3" class="text-left">
              End Date: {{ getDateWithFormat(upcomingPlan.endDate) }}
            </CCol>
            <CCol md="3" class="text-left">
              Amount: {{ upcomingPlan.amount }}
            </CCol>
            <CCol md="3" class="text-left">
              Discount: {{ upcomingPlan.discountValue }}
            </CCol>
            <CCol md="3" class="text-left">
              Total Amount: {{ upcomingPlan.totalAmount }}
            </CCol>
            <CCol md="3" class="text-left" v-show="upcomingPlan.invoiceUuid">
              <a color="primary" size="sm" _target="blank" :href="upcomingPlan.invoiceUrl">View Invoice</a>
            </CCol>
          </CRow>
        </CCol>
        <hr/>
      </div>



      <div v-if="plans.length > 0">
        <h6 v-show="plans"><u>Plan Purchase History</u></h6>
        <table v-show="plans" class="table table-striped table-bordered table-hover">
          <thead>
          <th>Name</th>
          <th>Type</th>
          <th>Duration</th>
          <th>Purchase Date</th>
          <th>Start Date</th>
          <th>Expiry Date</th>
          <th>Plan Members</th>
          <!-- <th>Amount</th> -->
          <!-- <th>Discount</th> -->
          <th>Total Amount</th>
          <th>Invoice</th>
          </thead>
          <tbody>
          <tr v-for="docs in plans" :key="docs.id">
            <td>{{ docs.name }}</td>
            <td>{{ docs.durationType }}</td>
            <td>
              {{ docs.duration }}
              {{
                docs.durationType === "Monthly"
                    ? "Month"
                    : docs.durationType === "Yearly"
                        ? "Year"
                        : "Days"
              }}
            </td>
            <td>{{ getDateWithFormat(docs.date) }}</td>
            <td>{{ getDateWithFormat(docs.startDate) }}</td>
            <td>{{ getDateWithFormat(docs.endDate) }}</td>
            <td>{{ docs.totalUsers }}</td>
            <!-- <td>{{ docs.discountValue }}</td> -->
            <td>{{ docs.totalAmount }}</td>
            <td>
              <a color="primary" v-if="docs.invoiceUuid" size="sm" _target="blank" :href="docs.invoiceUrl">View
                Invoice</a>
              <a color="primary" else>No invoice For Free</a>
            </td>

            <td>
              <CButton color="primary" v-on:click="viewPurchasedMembers(docs.planId,docs.companyId)">
                <i class="fas fa-user"></i>
              </CButton>
            </td>

          </tr>
          </tbody>
        </table>
      </div>


      <div v-show="purchasedMembers">
        <h6 ><u>Purchased Members List</u></h6>
        <table class="table table-striped table-bordered table-hover">
          <thead>
          <th>Name</th>
          <th>Purchased Date</th>
          <td>No Of Users</td>
          <th>Amount</th>
          <th>Discount Code</th>
          <th>Discount Value</th>
          <th>On Discount</th>
          <td>Discount Type</td>
          <td>Total Amount</td>
          <th>Invoice</th>
          </thead>
          <tbody>
          <tr v-for="member_history in purchasedMembers" :key="member_history.id">
            <td>{{ member_history.name }}</td>
            <td>{{ getDateWithFormat(member_history.createdAt) }}</td>
            <td>{{ member_history.noOfUsers }}</td>
            <td>{{ member_history.amount }}</td>
            <td>{{ member_history.discountCode }}</td>
            <td>{{ member_history.discountValue }}</td>
            <td>{{ member_history.onDiscount }}</td>
            <td>{{ member_history.discountType }}</td>
            <td>{{ member_history.totalAmount}}</td>
            <td>
              <a color="primary" v-if="member_history.invoiceUuid" size="sm" _target="blank" :href="member_history.invoiceUrl">
                View Invoice</a>
              <a color="primary" v-else>No invoice</a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

    </CCardBody>

  </div>
</template>
<script>
import {Mixin} from "/src/mixins/index.js";

export default {
  name: "PlanDetail",
  mixins: [Mixin],
  data() {
    return {
      self: this,
      userId: 0,
      plans: [],
      purchasedMembers:[],
      activePlan: {},
      upcomingPlan: {}
    };
  },
  created() {
    let self = this;
    const id = self.$route.params.id;

    axios.get("users/profiles/view-plans/" + id+'/College').then((response) => {
      if (response.status === 200) {
        let responseData = response.data;
        self.plans = responseData.data.plans;
        self.activePlan = responseData.data.activePlan;
        self.upcomingPlan = responseData.data.upcomingPlan;
      }
    });


  },
  methods: {
    viewPurchasedMembers(planId,companyId) {
      let self = this;
      self.purchasedMembers =[];
      axios.get("users/profiles/list-plan-users/" + planId+'/'+companyId).then((response) => {
        if (response.status === 200) {
          let responseData = response.data.data;
          self.purchasedMembers = responseData;
        }
      });
    },
    editPermission(paymentId) {
      this.$router.push({path: `/college/edit-college-plan-permissions/${paymentId}`});
    }

  },
};
</script>
