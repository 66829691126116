<template>
  <div class="detail-div">
    <v-server-table :columns="columns" :url="listUrl" :options="options" ref="myTable" @loaded="exports(self, 'Excel', module, 'Branch')">
      <template #branchName="data">
        <span :title="data.row.branchName">{{ trimfunction(data.row.branchName, 20) }}</span>
      </template>
      <template #address="data">
        <span :title="data.row.address">{{ trimfunction(data.row.address, 60) }}</span>
      </template>
    </v-server-table>
  </div>
</template>
<script>

import {branch} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";

export default {
  name: "BrancheTab",
  mixins: [Mixin],
  data() {
    return {
      listUrl: '/college/campuses',
      self: this,
      columns: ["branchName", "isHeadOffice", "address", "locationChangeCount"],
      module: branch,
      options: {
        headings: {
          branchName: this.$lang.campus.table.branchName,
          isHeadOffice: this.$lang.campus.table.isHeadOffice,
          address: this.$lang.campus.table.address,
          locationChangeCount: this.$lang.campus.table.locationChangeCount,
        },
        editableColumns: [
          "branchName",
          "isHeadOffice",
          "address",
        ],
        sortable: ["branchName", "isHeadOffice", "address"],
        filterable: ["branchName", "isHeadOffice", "address"],
      },
    };
  },
  created() {
    let self = this;
    self.dismissCountDown = 0;
    self.listUrl = self.listUrl + "/" + this.$route.params.id;
    self.dismissCountDown = 0;
  },
};
</script>
