<template>
  <CRow>
    <CCol col="12">
      <div class="row">
        <CCol sm="12" class="align-self-center">
          <div class="text-sm-right mt-3 mt-sm-0">
            <CButtonGroup class="file-export">
              <CButton color="secondary" disabled>
                {{ $lang.buttons.export.title }}
              </CButton>
              <CButton
                  color="danger"
                  v-c-tooltip="$lang.buttons.export.pdf"
                  v-on:click="
                  exports(self, 'pdf', module, 'College Stream', self.editId)
                "
              >
                <i class="fas fa-file-pdf"></i>
              </CButton>
              <VueJsonToCsv
                  class="btn btn-success"
                  v-c-tooltip="$lang.buttons.export.excel"
                  :json-data="json_data"
                  :labels="json_label"
                  :csv-title="json_title"
              >
                <i class="fas fa-file-excel"></i>
              </VueJsonToCsv>
            </CButtonGroup>
          </div>
        </CCol>
      </div>
      <CAlert color="success" closeButton :show.sync="dismissCountDownS" fade>{{ alertMessage }}</CAlert>
      <div id="people">
        <v-server-table :columns="columns" :url="listUrl" :options="options" ref="myTable" @loaded="exports(self, 'Excel', module, 'College Stream', self.editId)">
          <template #stream="data">
            {{ data.row.stream.name }}
          </template>
          <template #status="data">
            <CBadge :color="statusBudget(data.row.status)">{{ data.row.status }}</CBadge>
          </template>
          <template #user="data">
            {{ data.row.user.name }}
          </template>
        </v-server-table>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import store from "/src/store/store.js";
import {Mixin} from "/src/mixins/index.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";
import {collegeStream} from "../../../store/url";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);

export default {
  name: "CollegeStreamList",
  mixins: [Mixin],
  components: {
    VueJsonToCsv,
  },
  data() {
    return {
      submitted: false,
      json_data: [],
      json_label: {},
      json_title: "",
      err_msg: "",
      viewSkill: "",
      largeModal: false,
      viewLargeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      activePage: 1,
      listUrl: "",
      module: collegeStream,
      columns: ["stream", "status", "user"],
      data: [],
      options: {
        headings: {
          stream: this.$lang.collegeStream.table.name,
          status: this.$lang.collegeStream.table.status,
          user: this.$lang.collegeStream.table.user,
        },
        editableColumns: [],
        sortable: [],
        filterable: [],
      },
    };
  },
  created() {
    let self = this;
    const id = this.$route.params.id;
    self.listUrl = "/college/streams/" + id;
  },
  mounted() {
    let self = this;
    const id = this.$route.params.id;
    self.dismissCountDown = 0;
    self.editId = id;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    localStorage.setItem("showAlert", "");
    store.commit("showLoader", false); // Loader Off
  },
};
</script>
