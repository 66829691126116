<template>
  <CRow>
    <CCol col="12">
      <div class="row">
        <CCol sm="12" class="align-self-center">
          <div class="text-sm-right mt-3 mt-sm-0">
            <CButtonGroup class="file-export">
              <CButton color="secondary" disabled>
                {{ $lang.buttons.export.title }}
              </CButton>
              <CButton color="danger" v-c-tooltip="$lang.buttons.export.pdf" v-on:click="exports(self,'pdf',module,'UserTitleRequest',self.editId)">
                <i class="fas fa-file-pdf"></i>
              </CButton>
              <VueJsonToCsv class="btn btn-success" v-c-tooltip="$lang.buttons.export.excel" :json-data="json_data" :labels="json_label" :csv-title="json_title">
                <i class="fas fa-file-excel"></i>
              </VueJsonToCsv>
            </CButtonGroup>
          </div>
        </CCol>
      </div>
      <CAlert color="success" closeButton :show.sync="dismissCountDownS" fade>{{ alertMessage }}</CAlert>
      <v-server-table
          :columns="columns"
          :url="listUrl"
          :options="options"
          ref="myTable"
          @loaded="exports(self,'Excel',module,'UserTitleRequest',self.editId)">
        <template #createdAt="data">
          {{ dateTimeUTCtoLocalFormat(data.row.createdAt) }}
        </template>
        <template #updatedAt="data">
          {{ dateTimeUTCtoLocalFormat(data.row.updatedAt) }}
        </template>
        <template #status="data">
          <CBadge :color="statusBudget(data.row.status)">{{ data.row.status }}</CBadge>
        </template>
        <template #actions="data">
          <CButton color="primary" v-c-tooltip="$lang.buttons.general.crud.view" v-on:click="viewTableRow(data.row.id)"><i class="fas fa-eye"></i></CButton>
        </template>
      </v-server-table>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import {
  city,
  country,
  companyChildUsers,
  state,
  users,
  companyUserLists,
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";
import store from "../../../store/store";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "GroupChangeList",
  mixins: [Mixin],
  components: {
    VueJsonToCsv,
  },
  props: [''],

  data() {
    return {
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false /* for filter toggle*/,
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      industryOptions: [],
      subIndustryOptions: [],
      selectedIndustry: [],
      countryOptions: [],
      StateOptions: [],
      cityOptions: [],
      userOptions: [],
      profileVerifiedOptions: [],
      activePage: 1,
      listUrl: "/users/group/request/list/",
      module: companyChildUsers,
      companyUserLists: companyUserLists,
      moduleUser: users,
      moduleCountry: country,
      moduleState: state,
      moduleCity: city,
      profile: {
        name: "",
        isVerified: "",
      },
      columns: ["name", "createdAt", "updatedAt", "status", "uploadDocByAdmin", "reason", "actions"],
      data: [],
      options: {
        headings: {
          name: this.$lang.group_change.table.name,
          createdAt: this.$lang.group_change.table.created_at,
          updatedAt: this.$lang.group_change.table.updated_at,
          status: this.$lang.group_change.table.status,
          uploadDocByAdmin: this.$lang.group_change.table.uploadDocByAdmin,
          reason: this.$lang.group_change.table.reason,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: [],
        sortable: [],
        filterable: [],
      },
    };
  },
  created() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    self.listUrl = self.listUrl + this.$route.params.userId;
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    viewTableRow(item) {
      let self = this;
      if (item === self.$route.params.id) {
        this.$router.go(this.$router.currentRoute)
      }
      this.$router.push({path: `/college/grouprequest/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
  },
};
</script>
