<template>
  <div class="detail-div">
    <CRow>
      <CModal title="Update Status" size="lg" :show.sync="largeModal" :no-close-on-backdrop="true" color="primary">
        <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>{{ err_msg }}</CAlert>
        <CForm novalidate @submit.prevent="onSubmit">
          <CCardBody>
            <div class="card">
              <div class="card-body">
                <div class="form-group">
                  <label class="form__label">{{ $lang.profileRequest.form.status }}
                    <required_span/>
                  </label>
                  <v-select :options="statusOptions" v-model="profileRequest.status"></v-select>
                  <small class="error" v-if="$v.profileRequest.status.$error && !$v.profileRequest.status.required">{{ $lang.profileRequest.validation.required.status }}</small>
                </div>
              </div>
            </div>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" size="sm" color="primary">
              <CIcon name="cil-check-circle"/>
              {{ $lang.buttons.general.crud.submit }}
            </CButton>
          </CCardFooter>
        </CForm>
        <template #footer style="display: none">
          <CButton @click="darkModal = false" color="danger" style="display: none">Discard
          </CButton>
          <CButton @click="darkModal = false" color="success" style="display: none">Accept</CButton>
        </template>
      </CModal>
      <CCol col="12">
        <div class="row">
          <CCol sm="12" class="align-self-center">
            <div class="text-sm-right mt-3 mt-sm-0">
              <CButtonGroup class="file-export">
                <CButton color="secondary" disabled>
                  {{ $lang.buttons.export.title }}
                </CButton>
                <CButton color="danger" v-c-tooltip="$lang.buttons.export.pdf" v-on:click="exports(self,'pdf',module,'Meeting',self.editId)">
                  <i class="fas fa-file-pdf"></i>
                </CButton>
                <VueJsonToCsv class="btn btn-success" v-c-tooltip="$lang.buttons.export.excel" :json-data="json_data" :labels="json_label" :csv-title="json_title">
                  <i class="fas fa-file-excel"></i>
                </VueJsonToCsv>
              </CButtonGroup>
            </div>
          </CCol>
        </div>
        <CAlert color="success" closeButton :show.sync="dismissCountDownS" fade>{{ alertMessage }}</CAlert>
        <v-server-table :columns="columns" :url="listUrl" :options="options" ref="myTable" @loaded="exports(self,'Excel',module,'Meeting',self.editId)">
          <template #date="data">
            {{ getDateWithFormat(data.row.date) }}
          </template>
          <template #actions="data">
            <CButton color="primary" v-c-tooltip="$lang.buttons.general.crud.viewRequest"
                     v-on:click="viewProfileUpdateRequestTableRow(data.row.id)">
              <i class="fas fa-eye"></i>
            </CButton>
          </template>
        </v-server-table>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import {college, verificationStatus} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import {required} from "vuelidate/lib/validators";
import required_span from "/src/components/layouts/general/required-span";
import VueJsonToCsv from "vue-json-to-csv";
Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);
export default {
  name: "MeetingList",
  mixins: [Mixin],
  components: {
    required_span,
    VueJsonToCsv,
  },
  data() {
    return {
      submitted: false,
      json_data: [],
      json_label: {},
      json_title: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      statusOptions: [],
      listUrl: "/users/profiles/verification/meeting-list/",
      module: college,
      moduleVerificationStatus: verificationStatus,
      profileRequest: {
        status: "",
        userId: "",
        companyId: "",
        requestId: "",
        requestedId: "",
      },
      columns: ["date", "startTime", "endTime", "meetingLink","reason", "staffName", "actions"],
      options: {
        headings: {
          date: this.$lang.profileRequest.meeting.table.date,
          endTime: this.$lang.profileRequest.meeting.table.endTime,
          meetingLink: this.$lang.profileRequest.meeting.table.meetingLink,
          staffName: this.$lang.profileRequest.meeting.table.staffName,
          startTime: this.$lang.profileRequest.meeting.table.startTime,
          reason  : this.$lang.profileRequest.meeting.table.reason,
          actions: this.$lang.profileRequest.meeting.table.action,
        },
        editableColumns: ["staffName"],
        sortable: [],
        filterable: ["staffName"],
      },
    };
  },
  validations: {
    profileRequest: {
      status: {
        required,
      },
    },
  },
  created() {
    let self = this;
    self.editId = this.$route.params.userId;
    self.listUrl = self.listUrl + self.editId;
  },
  mounted() {
    let self = this;
    self.editId = this.$route.params.userId;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
  },
  methods: {
    onSubmit() {
      let self = this;
      this.$v.profileRequest.$touch();
      if (this.$v.profileRequest.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";
        if (this.submitType === "Update") {
          setTimeout(() => {
            let self = this;
            const postData = {
              id: self.editId,
              statusId: self.profileRequest.status.value,
            };
            axios
              .post('users/profiles/verification/meeting-list-update', postData)
              .then(function (response) {
                self.largeModal = false;
                self.$refs.myTable.refresh();
                self.alertMessage = response.data.message;
                self.dismissCountDownS = 10;
              })
              .catch(function (error) {
                self.submitted = false; //Enable Button
              });
              self.submitted = true;
          }, 500);
        }
      }
    },
    viewProfileUpdateRequestTableRow(item) {
      let self = this;
      self.submitType = "Update";
      self.editId = item;
      self.largeModal = true;
      self.statusOptions = [];
      self.profileRequest.status = {};
      axios.get('meeting/reason/list')
          .then((response) => {
            response.data.data.map(function (value, key) {
              self.statusOptions.push({value: value.id, label: value.title});
            });
          });
      axios.get('users/profiles/verification/meeting-detail/' + item)
        .then((response) => {
          if (response.data.reason) {
            self.profileRequest.status = {
              'value': response.data.reason.id,
              'label': response.data.reason.title,
            };
          }
        });
    },
  },
};
</script>
