<template>
  <CRow>
    <CCol col="12">
      <div class="row">
        <CCol sm="12" class="align-self-center">
          <div class="text-sm-right mt-3 mt-sm-0">
            <CButtonGroup class="file-export">
              <CButton color="secondary" disabled>
                {{ $lang.buttons.export.title }}
              </CButton>
              <CButton
                  color="danger"
                  v-c-tooltip="$lang.buttons.export.pdf"
                  v-on:click="exports(self, 'pdf', module, 'Student')"
              >
                <i class="fas fa-file-pdf"></i>
              </CButton>
              <VueJsonToCsv
                  class="btn btn-success"
                  v-c-tooltip="$lang.buttons.export.excel"
                  :json-data="json_data"
                  :labels="json_label"
                  :csv-title="json_title"
              >
                <i class="fas fa-file-excel"></i>
              </VueJsonToCsv>
            </CButtonGroup>
          </div>
        </CCol>
      </div>

      <CAlert
          color="success"
          closeButton
          :show.sync="dismissCountDownS"
          fade
      >{{ alertMessage }}
      </CAlert
      >
      <v-server-table
          :columns="columns"
          :url="listUrl"
          :options="options"
          ref="myTable"
          @loaded="exports(self, 'Excel', module, 'Student')"
      >
        <template #country="data">
          {{ data.row.country.name }}
        </template>
        <template #actions="data">
          <CButton
              color="warning"
              v-c-tooltip="$lang.student.crud.view"
              v-on:click="viewTableRow(data.row.id)"
          >
            <i class="fas fa-eye"></i>
          </CButton>
        </template>
      </v-server-table>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import {student} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import moment from "moment";
import "vue-simple-range-slider/dist/vueSimpleRangeSlider.css";
import VueJsonToCsv from "vue-json-to-csv";
Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);
export default {
  name: "Student",
  mixins: [Mixin],
  components: {
    VueJsonToCsv,
  },
  data() {
    return {
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      range: [0, 1000000],
      number: 1,
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      listUrl: "",
      activePage: 1,
      module: student,
      filter: {
        code: "",
      },
      columns: ["firstName","nickname","description","enrollmentNo","enrollmentYear","passingYear", "actions"], //'status'
      data: [],
      codeOptions: [],
      options: {
        headings: {
          firstName: this.$lang.student.table.firstName,
          nickname: this.$lang.student.table.nickname,
          description: this.$lang.student.table.description,
          enrollmentNo: this.$lang.student.table.enrollmentNo,
          enrollmentYear: this.$lang.student.table.enrollmentYear,
          passingYear: this.$lang.student.table.passingYear,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: [],
        sortable: [],
        filterable: [],
      },
    };
  },
  created()
  {
    let self = this;
    const id = this.$route.params.id;
    self.listUrl = "/student/list/" + id;
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    this.$root.$on("alert", (arg1, arg2) => {
    });
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem("showAlert", "");
    axios.get(this.listUrlApi(this.module)).then((response) => {
      response.data.data.map(function (value, key) {
        self.codeOptions.push({value: value.id, label: value.code});
      });
    });
  },
  methods: {
    getHumanDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    switchFilter(show) {
      this.toggleFilterValue = show;
    },
    setAlert(value) {
      this.showAlert = value;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios
              .delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.response.message;
                  self.dismissCountDownS = 10;
                }
              });
        }
      });
    },
    viewTableRow(item) {
      this.$router.push({path: `/student/view/${item}`});
    },
  },
};
</script>
